/* nav-global
---------------------------------------------------------- */
.nav-global {

  @include mq-sp {
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 100%;
    z-index: 99;
    display: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: #fff url(/img/common/img_maining_sp.png) center center no-repeat;
      background-size: cover;
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff,.85);
      z-index: -1;
    }
  }

  @include mq-pc {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  .list-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq-sp {
      display: block;
      position: absolute;
      top: 53%;
      left: 50%;
      width: 160px;
      transform: translate(-50%, -50%);
      margin-top: -60px;
    }

    .item {
      align-self: center;
      align-items: center;
      text-align: center;
      padding: 0 15px;
      height: 100%;
      display: flex;

      a {
        display: block;

        @include mq-sp {
          padding: 15px 0 10px;
          margin-bottom: 15px;
        }
      }

      @include mq-sp {
        margin: 0;
        padding: 0;
        height: auto;
        display: block;

        &:last-child {
          margin-bottom: 0;
        }

      }

      @include mq-pc {
        &:hover {
          box-shadow: inset 0px -2px 0px 0px #8F4AB1;

          .jp {
            color: #8F4AB1;
          }
        }
      }

      .en {
        display: block;
        color: $color-pink;
        font-size: 10px;
        line-height: 15px;

        @include mq-sp {
          margin-bottom: 7px;
        }

      }

      .jp {
        display: block;
        color: $color-grey;
        font-size: 14px;
        line-height: 20px;
      }

      @include mq-pc {
        &.is-current {
          box-shadow: inset 0px -2px 0px 0px #9B72AF;
        }
      }

      @include mq-sp {
        &.is-current {
          a {
            box-shadow: inset 0px -2px 0px 0px #9B72AF;
          }
        }
      }
    }

  }

  .btn-contact {
    width: 80px;
    background: $color-purple;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    transition: opacity 0.2s ease 0s;

    &:hover {
      background-color: #8F4AB1;
    }

    @include mq-sp {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 60px;
      left: 0;
    }

    .icon {
      position: relative;
      display: block;
      width: 16px;
      height: 16px;
      margin-top: 10px;

      @include mq-pc {
        margin-top: 13px;
      }

      &::before {
        content: '';
        position: absolute;
        background: url(/img/common/icon_support.png) center center no-repeat;
        background-size: 100%;
        top: 0px;
        width: 16px;
        height: 12px;
      }
    }

    .text {
      font-size: 14px;
    }
  }
}