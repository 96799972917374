@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 section
========================================================== */
/* c-sec-border
---------------------------------------------------------- */
.c-sec-border {
  margin-bottom: 40px;
  padding-top: 20px;

  .inner {
    padding-right: 12%;
    padding-left: 12%;
    padding-bottom: 40px;
    border: 1px solid $color-pink;
  }

  .heading {
    position: relative;
    z-index: 1;
    margin-top: -25px;
    margin-bottom: 25px;
    background-color: #fff;

    .title {
      margin-bottom: 0;
    }

    .text {
      text-align: center;
      font-size: 1.8rem;
    }
  }
  
  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 35px;

    .inner {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}


