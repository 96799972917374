@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 header
========================================================== */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: #fff;

  .header-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .logo {
      margin-left: 25px;

      a {
        display: block;
        width: 175px;
        transition: opacity 0.2s ease 0s;
        
         &:hover {
            opacity: 0.7;
         }

        img {
          width: 100%;
        }
      }

      @include mq-sp {
        margin-left: 5px;
      }
    }

    .btn-sp-menu {
      display: none;

      @include mq-sp {
        display: flex;
      }
    }
  }

  @include mq-sp {
    position: fixed;
    height: 60px;

    .btn-sp-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;

      .img-close {
        display: none;
      }

      &.is-open {
        .img-open {
          display: none;
        }

        .img-close {
          display: flex;
        }
      }
    }
  }
}
