@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 list
========================================================== */
/* c-list-news
---------------------------------------------------------- */
.c-list-news {
  .item {
    padding: 15px 0 25px;
    border-top: 1px solid #E1E1E1;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .date {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }

  .link {
    text-decoration: underline;
    color: #e45744;

    &:hover {
      color: #ff5e48;
    }
  }
}

/* c-list-order
---------------------------------------------------------- */
.c-list-order {
  counter-reset: custom-counter;
  
  > .item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    counter-increment: custom-counter;

    &:before {
      content: counter(custom-counter);
      position: absolute;
      top: 0;
      left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--bracket {
    > .item {
      &:nth-child(n+10) {
        padding-left: 45px;
      }

      &:before {
        content: '('counter(custom-counter)')';
      }
    }

    .c-list-order {
      margin-left: -10px;
    }
  }

  &--circle {
    > .item {
      &:before {
        top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border: 1px solid $color-grey;
        border-radius: 50%;
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: -1px;
      }
    }
  }
}