@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 footer
========================================================== */
.footer {
  position: relative;
  background-color: #F5F1F7;
  overflow: hidden;
  
  .btn-pagetop {
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 10;
    width: 44px;
    height: 44px;
    a {
      display: block;
      width: 44px;
      height: 44px;

      img {
        width: 100%;
      }

      @include is-opacity;
    }

    @include mq-sp {
      right: 20px;
    }
  }

  .footer-in {
    display: flex;
    flex-direction: column;
    padding: 40px 0 30px;
    @include mq-sp {
      padding: 0;
    }
    .list-link {
      display: flex;
      margin: 0 -15px;
      .item {
        margin: 0 15px;
        a {
          font-size: 12px;
          color: $color-pink;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      @include mq-sp {
        flex-direction: column;
        margin: 0;
        padding: 0;
        font-size: 0;
        .item {
          margin: 0 0 15px  0;
        }
      }
    }

    .copyright {
      font-size: 12px;
      color: $color-grey;
      margin-top: 35px;

      @include mq-sp {
        margin-top: 25px;
      }
    }
  }

  @include mq-sp {
    padding: 20px 10px 15px;

    .btn-pagetop {
      right: 20px;
    }

  }
}