@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 link
========================================================== */
/* c-link-text
---------------------------------------------------------- */
.c-link-text {
  position: relative;
  text-decoration: underline;
  color: $color-orange;

  &:hover {
    color: #ff5e48;
    text-decoration: none;
  }
  
  &:visited {
    color: #D53C27;
  }

  &--blank {
    padding-right: 15px;

    &:after {
      content: "";
      position: absolute;
      top: 9px;
      right: 0;
      width: 10px;
      height: 10px;
      background-image: url('/img/common/icon_blank.png');
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }
}