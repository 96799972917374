@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 table
========================================================== */
/* c-table
---------------------------------------------------------- */
.c-table {
  width: 100%;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  tr {
    border-bottom: 4px solid #fff;

    &:last-child {
      border-bottom: 0;
    }
  }

  th {
    vertical-align: middle;
    padding: 6px 10px;
    background-color: #f5f5f5;
    font-weight: 700;
    line-height: 2;
  }

  td {
    vertical-align: top;
    padding-left: 20px;

    .detail {
      padding: 6px 0;
      border-bottom: 1px solid $color-grey--light;
      line-height: 2;
    }
  }

  @include mq-pc {
    th {
      width: 33.33%;
    }

    td {
      width: 66.67%;
    }
  }

  @include mq-sp {
    display: block;

    tbody {
      display: block;
    }

    tr {
      display: block;
      border-width: 3px;
    }

    th {
      display: block;
    }

    td {
      display: block;
      padding-left: 0;

      .detail {
        padding: 6px 15px;
      }
    }
  }
}