/* ==========================================================
 fonts
========================================================== */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,700,700italic');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700');

/* ==========================================================
 base
========================================================== */
@import './base/reset.scss';
@import './base/layout.scss';

/* ==========================================================
 components
========================================================== */
@import './components/header.scss';
@import './components/nav-global.scss';
@import './components/footer.scss';
@import './components/heading.scss';
@import './components/text.scss';
@import './components/link.scss';
@import './components/button.scss';
@import './components/list.scss';
@import './components/table.scss';
@import './components/box.scss';
@import './components/form.scss';
@import './components/section.scss';
@import './components/mainvisual.scss';

/* ==========================================================
 utils
========================================================== */
@import './utils/display.scss';
@import './utils/flex.scss';
@import './utils/spacing.scss';
@import './utils/text_align.scss';

/* ==========================================================
 pages
========================================================== */




