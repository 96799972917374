
/* color
---------------------------------------------------------- */
$color-grey: #4a4a4a;
$color-grey--light: #CECECE;
$color-pink: #E861A4;
$color-purple: #9b72af;
$color-purple-light: #F5F1F7;
$color-orange: #e45744;

/* breakpoint
---------------------------------------------------------- */
$pc-breakpoint: 641px;
