
@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 layout
========================================================== */
body {

  @include mq-sp {
    min-width: 1px;
  }
}
/* wrapper
---------------------------------------------------------- */
.wrapper {
  overflow: hidden;
  padding-top: 60px;

  @include mq-sp {
    padding-top: 60px;
  }
}

/* nav-breadcrumb
---------------------------------------------------------- */
.nav-breadcrumb {

}

/* contents
---------------------------------------------------------- */
.contents {
  
  &.subpage-contents {
    padding-bottom: 50px;

    @include mq-sp {
      padding-bottom: 30px;
    }
  }
}

/* container
---------------------------------------------------------- */
.container {
  @include mq-pc {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
  }

  @include mq-mx(1024) {
    width: 93.75%;
  }

  @include mq-sp {
    width: 100%;
  }
}

/* grid
---------------------------------------------------------- */
.c-grid {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {

  }

  .col-6 { width: 50%; }
  .col-4 { width: 33.33%; }
  .col-3 { width: 25%; }

  @include mq-pc {
    .col-pc-6 { width: 50%; }
    .col-pc-4 { width: 33.33%; }
    .col-pc-3 { width: 25%; }
  }

  @include mq-sp {
    .col-sp-6 { width: 50%; }
    .col-sp-4 { width: 33.33%; }
    .col-sp-3 { width: 25%; }
  }
}
