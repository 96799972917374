@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 box
========================================================== */
/* c-box-image
---------------------------------------------------------- */
.c-box-image {
  margin-bottom: 30px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }

  @include mq-sp {
    img {
      width: 100%;
    }
  }
}

/* c-box-intro
---------------------------------------------------------- */
.c-box-intro {
  padding-right: 12.5%;
  padding-left: 12.5%;
  margin-bottom: 30px;

  .text {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  + .c-sec-border {
    position: relative;
    margin-top: 20px;
  }

  @include mq-sp {
    padding-right: 20px;
    padding-left: 20px;
  }
}

/* c-box-card
---------------------------------------------------------- */
.c-box-card {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .image {
    img {
      max-width: 100%;
    }
  }

  .detail {

  }

  @include mq-pc {
    display: flex;

    .image {
      width: 33.33%;
      order: 0;
    }
  
    .detail {
      width: 66.67%;
      order: 1;
      padding-left: 20px;
    }
  }

  @include mq-sp {
    .image {
      margin-bottom: 25px;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .detail {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}