/* ==========================================================
 mainvisual
========================================================== */
/* c-mainvisual
---------------------------------------------------------- */
.c-mainvisual {
  position: relative;
  margin-bottom: 40px;

  .bg {
    position: relative;
    height: 310px;
    overflow: hidden;

    @include mq-sp {
      height: auto;
      padding-top: 86%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);

      @include mq-mx(1024) {
        width: auto;
        height: 100%;
      }

      @include mq-sp {
        width: 100%;
        height: 100%;
      }
    }
  }

  .box-text {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 960px;
    height: 250px;
    background-color: rgba(255, 255, 255, 0.8);

    @include mq-mx(1024) {
      width: 93.75%;
    }

    @include mq-sp {
      width: 100%;
    }

    .wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      transform: translate(-50%, -50%);

      @include mq-sp {
        margin-top: -15px;
      }
    }

    .en {
      margin-bottom: 5px;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 1.3;
      color: $color-pink;
      text-align: center;
    }

    .jp {
      padding-top: 10px;
      border-top: 1px solid $color-pink;
      font-size: 3.6rem;
      text-align: center;
      line-height: 1.3;
      letter-spacing: 3px;
    }
  }

  &--no-bg {
    height: 200px;
    margin-bottom: 0;

    .box-text {
      top: 50%;
      bottom: inherit;
      height: auto;
      transform: translate(-50%, -50%);
      width: 100%;
      background-color: transparent;
      text-align: center;

      .wrap {
        display: inline-block;
        position: static;
        margin-top: 0;
        transform: translate(0, 0);
      }
    }
  }
}