@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 heading
========================================================== */
/* c-hdg
---------------------------------------------------------- */
.c-hdg {

  &-01 {
    margin-bottom: 25px;
    font-size: 2.8rem;
    line-height: 1.5;
    color: $color-pink;
    text-align: center;
    letter-spacing: 1px;
  }

}


