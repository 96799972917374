@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 button
========================================================== */
/* c-btn
---------------------------------------------------------- */
.c-btn {
  display: inline-block;
  min-width: 200px;
  padding: 13px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: 700;
  color: $color-purple;
  text-align: center;

  @include is-opacity;

  &--primary {
    background-image: linear-gradient(to bottom, #E6ABF0, #9B71AE);
    color: #fff;

    &:hover {
      opacity: 1;
      background-image: linear-gradient(to bottom, #9B71AE, #9B71AE);
    }
  }

  &.d-block {
    min-width: auto;
  }

  // size
  &--sm {
    padding: 12px 10px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}