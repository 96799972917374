@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 text comp
========================================================== */
/* c-txt-read
---------------------------------------------------------- */
.c-txt-read {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
}

/* c-txt-intro
---------------------------------------------------------- */
.c-txt-intro {
  margin-bottom: 35px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 18px;
  }
}

/* c-txt-pgh
---------------------------------------------------------- */
.c-txt-pgh {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 10px;
  }
}

/* c-txt-note
---------------------------------------------------------- */
.c-txt-note {
  color: $color-grey;
  font-size: 1.2rem;
}

/* c-txt-indent
---------------------------------------------------------- */
.c-txt-indent {
  text-indent: 1em;
}

/* c-txt-small
---------------------------------------------------------- */
.c-txt-sm {
  font-size: 1.2rem;
}

/* ==========================================================
 text weight
========================================================== */
/* c-txt-mid
---------------------------------------------------------- */
.c-txt-mid {
  font-weight: 600;
}

/* c-txt-bold
---------------------------------------------------------- */
.c-txt-bold {
  font-weight: 700;
}

/* ==========================================================
 text color
========================================================== */
/* c-txt-grey
---------------------------------------------------------- */
.c-txt-grey {
  color: $color-grey;
}

/* c-txt-black
---------------------------------------------------------- */
.c-txt-black {
  color: #000;
}

/* c-txt-purple
---------------------------------------------------------- */
.c-txt-purple {
  color: #8F4AB1;
}

